/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
//styles
import "./src/styles/global.scss"
//PageWrapping
import React from "react"
import { AnimatePresence } from "framer-motion"

export function wrapPageElement({ element, props }) {
  let framerMode = "wait"
  // console.log(props)
  // if (props.path === "/projects/") {
  //   framerMode = "wait"
  // }
  return <AnimatePresence mode={framerMode}>{element}</AnimatePresence>
}

//Logic for knowing where a page has been linked to from for menu animation
export function onRouteUpdate({ location, prevLocation }) {
  console.log("new pathname", location.pathname)
  console.log("old pathname", prevLocation ? prevLocation.pathname : null)
  if (location && location.state)
    location.state.referrer = prevLocation ? prevLocation.pathname : null
}

//Stop gatsby scrolling to top during fade animation on route change
export function shouldUpdateScroll({}) {
  return false
}
